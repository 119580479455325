import Vue from 'vue'
import VueRouter from 'vue-router'
import login from '../views/login.vue'
import index from '../views/index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: index
  },
  {
    path: '/login',
    name: 'login',
    component: login
  },
  {
    path: '/index',
    name: 'index',
    component: index
  },
  {
    path: '/index1',
    name: 'index1',
    component: () => import('../views/index1-single.vue')
  },
  {
    path: '/index2',
    name: 'index2',
    component: () => import('../views/index2-single.vue')
  },
  {
    path: '/index3',
    name: 'index3',
    component: () => import('../views/index3-single.vue')
  },
  {
    path: '/index4',
    name: 'index4',
    component: () => import('../views/index4-single.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
