<template>
  <div class="chart" id="chart" ref="chart"></div>
</template>

<script>
import { barStep } from '../../config'

export default {
  name: 'pillar-overlay',
  props: {
    xData: {
      type: Array,
      default () {
        return []
      }
    },
    yData1: {
      type: Array,
      default () {
        return []
      }
    },
    yData2: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      startValue: 0,
      endValue: 33,
      timer: null
    }
  },
  computed: {
    option () {
      return {
        legend: {
          right: '5%',
          bottom: '5%',
          orient: 'vertical',
          itemWidth: 20,
          textStyle: {
            color: '#fff',
            fontSize: 20
          }
        },
        grid: {
          left: '3%',
          right: '20%',
          bottom: '3%',
          top: '10%',
          containLabel: true
        },
        dataZoom: [
          {
            type: 'slider',
            orient: 'vertical',
            start: this.startValue,
            end: this.endValue,
            right: -100
          }
        ],
        xAxis: {
          type: 'value',
          axisTick: {
            alignWithLabel: true
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#00558a',
              type: [5, 10],
              dashOffset: 5
            }
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: '#00558a',
              type: [5, 10],
              dashOffset: 5
            }
          },
          axisLabel: {
            textStyle: {
              color: '#fff',
              fontSize: 20
            },
            margin: 20
          },
          name: '辆',
          nameTextStyle: {
            color: '#fff',
            fontSize: 20
          }
        },
        yAxis: {
          type: 'category',
          axisLine: {
            show: true,
            lineStyle: {
              color: '#00A8FF'
            }
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            textStyle: {
              color: '#fff',
              fontSize: 20
            },
            margin: 20
          },
          data: this.xData
        },
        series: [
          {
            name: '运营',
            type: 'bar',
            stack: '总量',
            barWidth: 30,
            itemStyle: {
              normal: {
                color: '#49A6F8',
                barBorderRadius: [0, 0, 0, 0]
              }
            },
            label: {
              normal: {
                show: true,
                position: 'inside',
                textStyle: {
                  color: '#fff',
                  fontSize: 20
                }
              }
            },
            z: 10,
            data: this.yData1
          },
          {
            name: '非运营',
            type: 'bar',
            stack: '总量',
            itemStyle: {
              normal: {
                color: '#F2A441',
                barBorderRadius: [0, 20, 20, 0]
              }
            },
            label: {
              normal: {
                show: true,
                position: 'inside',
                textStyle: {
                  color: '#fff',
                  fontSize: 20
                }
              }
            },
            z: 5,
            data: this.yData2
          }
        ]
      }
    }
  },
  destroyed () {
    clearInterval(this.timer)
  },
  methods: {
    init () {
      const xLen = this.xData.length
      this.tieNum = 100 / xLen
      this.startValue = 100 - 100 / 5
      this.endValue = 100
      this.chart = this.$echarts.init(this.$refs.chart)
      this.chart && this.chart.setOption(this.option)
      this.circulation()
    },
    circulation () {
      if (this.timer) clearInterval(this.timer)
      this.timer = setInterval(() => {
        if (this.startValue <= 0) {
          this.startValue = 100 - 100 / 5
          this.endValue = 100
        } else {
          this.startValue -= this.tieNum
          this.endValue -= this.tieNum
        }
        this.chart = this.$echarts.init(this.$refs.chart)
        this.chart && this.chart.setOption(this.option)
      }, barStep * 1000)
    }
  }
}
</script>

<style lang="less" scoped>

</style>
