<template>
  <div class="chart" id="chart" ref="chart"></div>
</template>

<script>
export default {
  name: 'pie-solid',
  props: {
    seriesData: {
      type: Array,
      default () {
        return []
      }
    }
  },
  computed: {
    option () {
      return {
        color: ['#009eff', '#ffa11a', '#fb4642'],
        legend: {
          left: '70%',
          bottom: 'center',
          orient: 'vertical',
          itemGap: 20,
          itemWidth: 20,
          itemHeight: 20,
          textStyle: {
            color: '#fff',
            fontSize: 22
          }
        },
        series: [
          {
            center: ['40%', '55%'],
            radius: '72%',
            type: 'pie',
            itemStyle: {
              normal: {
                color: 'rgba(250,250,250,0.5)'
              }
            },
            data: [
              {
                value: 0
              }
            ],
            label: {
              normal: {
                show: false
              }
            }
          },
          {
            center: ['40%', '55%'],
            type: 'pie',
            radius: '70%',
            itemStyle: {
              normal: {
                borderColor: '#fff',
                borderWidth: 2
              }
            },
            label: {
              color: '#ffffff',
              fontSize: 20,
              fontWeight: 'normal',
              position: 'inner',
              formatter (params) {
                const { value } = params
                return value
              }
            },
            data: this.seriesData
          }
        ]
      }
    }
  },
  methods: {
    init () {
      if (this.chart) this.chart.clear()
      this.chart = this.$echarts.init(this.$refs.chart)
      this.chart && this.chart.setOption(this.option)
    }
  }
}
</script>

<style lang="less" scoped></style>
