/* eslint-disable */

import axios from 'axios'
import router from '../router'
import { cache, baseURL } from '../config'
import { message } from 'ant-design-vue'

/**
 * 请求失败后的错误统一处理
 * @param {Number} status 请求失败的状态码
 */
const errorHandle = (status, other) => {
  // 状态码判断
  switch (status) {
  case 401:
    message.destroy()
    message.error('未授权, 请重新登录!')
    router.push({ path: '/login' })
    break
    // 403 token过期, 清除token并跳转登录页
  case 403:
    console.log('登录信息过期，请重新登录')
    break
  case 404:
    console.log('网络请求不存在')
    break
  case 500:
    console.log('服务器内部错误')
    break
  case 504:
    console.log('服务器连接超时')
    break
  default:
    console.log(other)
    break
  }
}

const Http = axios.create({
  // 设置超时时间
  timeout: 10000 * 1000,
  baseURL: baseURL
})
Http.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
/**
 * 请求前拦截
 * 用于处理需要在请求前的操作
 */
Http.interceptors.request.use(config => {
  const token = localStorage.getItem(cache.token) || ''
  if (token) {
    config.headers['X-Access-Token'] = token // 让每个请求携带自定义 token 请根据实际情况自行修改
  }
  // 序列化post请求
  return config
}, (error) => {
  return Promise.reject(error)
})

/**
 * 请求响应拦截
 * 用于处理需要在请求返回后的操作
 */
Http.interceptors.response.use(
  res => {
    return res.status === 200 ? Promise.resolve(res.data) : Promise.reject(res.data)
  },
  error => {
    const { response } = error
    if (response) {
      errorHandle(response.status, response.data.message)
      return Promise.reject(response)
    } else {
      // 处理断网的情况
      if (error.message.includes('timeout')) {
        console.log('请求超时，请检查网络是否连接正常')
      } else {
        console.log('请求失败，请检查网络是否已连接')
      }
    }
  })

export default Http
