<template>
  <div v-if="list.length" class="map-table" :class="{red:isHq}">
    <p class="title">{{ title }}</p>
    <ul class="list">
      <li class="item item-title">
        <p class="item0">销售省份</p>
        <p class="item1">销量</p>
      </li>
      <div class="table-scroll-wrapper">
        <vue-seamless-scroll
            :data="list"
            :class-option="defaultOption">
          <li class="item" v-for="(item,index) in list" :key="index">
            <p class="item0">{{ item.name }}</p>
            <p class="item1">{{ item.value }}</p>
          </li>
        </vue-seamless-scroll>
      </div>
    </ul>
  </div>
</template>

<script>
import vueSeamlessScroll from '../scroll-list'
import { tableScrollStep } from '../../config'

export default {
  name: 'index',
  components: {
    vueSeamlessScroll
  },
  props: {
    list: {
      type: Array,
      default () {
        return []
      }
    },
    isHq: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: '红旗'
    }
  },
  data () {
    return {
      defaultOption: {
        step: tableScrollStep, // 数值越大速度滚动越快
        limitMoveNum: 2, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: false, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000 // 单步运动停止的时间(默认值1000ms)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.map-table {
  width: 658px;
  height: 1242px;
  overflow: hidden;
  background: url("../../assets/images/map-table-bg.png") no-repeat;

  &.red {
    .title {
      color: #FF0000;
    }

    .list {
      .item {
        &.item-title {
          color: #FF0000;
        }
      }
    }
  }

  .title {
    font-weight: 600;
    font-size: 34px;
    text-align: center;
    padding: 17px 0 20px;
    color: #65CCFF;
  }

  .list {
    .table-scroll-wrapper {
      overflow: hidden;
    }

    .item {
      font-size: 30px;
      color: #D4EAF6;
      padding: 12px 0;

      &.item-title {
        color: #65CCFF;
        font-size: 36px;
        padding: 24px 0;
        background: rgba(241, 241, 241, 0.15);
        box-shadow: 0 5px 10px -5px rgba(0, 168, 255, 0.2);
      }

      &:nth-child(even) {
        background: rgba(241, 241, 241, 0.15);
      }

      .item0 {
        display: inline-block;
        width: 50%;
        text-align: center;
      }

      .item1 {
        display: inline-block;
        width: 50%;
        text-align: center;
      }
    }
  }
}
</style>
