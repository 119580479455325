import http from './http'

// 获取验证码
export function getRandomCode (currdatetime) {
  return http({
    url: `/sys/randomImage/${currdatetime}`,
    method: 'get'
  })
}

// 登录
export function postLogin (data) {
  return http({
    url: '/sys/login',
    method: 'post',
    data
  })
}

// 退出登录
export function postLogout (data) {
  return http({
    url: '/sys/logout',
    method: 'post',
    data
  })
}

// 获取车辆分布数据
export function getSalesData (data) {
  return http({
    url: '/rms/sales/all',
    method: 'get',
    data
  })
}

// 获取回收数据
export function getRecoveryData (data) {
  return http({
    url: '/rms/recovery/all',
    method: 'get',
    data
  })
}

// 获取租赁数据
export function getRentData (data) {
  return http({
    url: '/rms/rent/all',
    method: 'get',
    data
  })
}

// 获取地图数据
export function getMapData (data) {
  return http({
    url: '/rms/map/all',
    method: 'get',
    data
  })
}

// 获取设备数据
export function getDeviceData (data) {
  return http({
    url: '/rms/device/all',
    method: 'get',
    data
  })
}
