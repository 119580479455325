<template>
  <div class="chart-wrapper">
    <div class="chart" id="chart" ref="chart"></div>
    <div class="legend-wrapper" :class="{'legend-wrapper-long':legendList.length>7}">
      <ul class="legend-list" v-if="legendList.length<7">
        <li class="legend-item" v-for="(item,index) in legendList" :key="index">
          <span class="icon" :style="{background:item.color}"></span>
          <span class="name" :style="{minWidth:nameWidth+'px'}">{{ item.name }}</span>
          <span class="percent">{{ item.percent }}%</span>
          <span class="count">{{ item.count }}</span>
        </li>
      </ul>
      <div v-if="legendList.length>7" style="height: 100%;">
        <vue-seamless-scroll
          :data="legendList"
          :class-option="defaultOption">
          <ul class="legend-list">
            <li class="legend-item" v-for="(item,index) in legendList" :key="index">
              <span class="icon" :style="{background:item.color}"></span>
              <span class="name" :style="{minWidth:nameWidth+'px'}">{{ item.name }}</span>
              <span class="percent">{{ item.percent }}%</span>
              <span class="count">{{ item.count }}</span>
            </li>
          </ul>
        </vue-seamless-scroll>
      </div>
    </div>
  </div>
</template>

<script>
import vueSeamlessScroll from '../scroll-list'

export default {
  name: 'pillar-single',
  components: {
    vueSeamlessScroll
  },
  props: {
    typeData: {
      type: Array,
      default () {
        return ['三元', '磷酸铁锂', '锰酸锂', '其他']
      }
    },
    data: {
      type: Array,
      default () {
        return [570, 108, 200, 0]
      }
    },
    colorList: {
      type: Array,
      default () {
        return []
      }
    },
    title: {
      type: String,
      default: ''
    },
    nameWidth: {
      type: Number,
      default: 40
    }
  },
  data () {
    return {
      defaultOption: {
        step: 0.5, // 数值越大速度滚动越快
        limitMoveNum: 2, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: false, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000 // 单步运动停止的时间(默认值1000ms)
      }
    }
  },
  computed: {
    total () {
      return this.data.length && this.sum(this.data)
    },
    percentData () {
      const arr = []
      this.data.length && this.total && this.data.forEach((item) => {
        arr.push(((item / this.total) * 100).toFixed(2))
      })
      return arr
    },
    legendList () {
      const arr = []
      this.data.length && this.data.forEach((item, index) => {
        arr.push({
          name: this.typeData[index],
          count: item,
          percent: this.percentData[index],
          color: this.colorList[index % 7]
        })
      })
      return arr
    },
    colorPercent () {
      const percentList = (this.percentData.length && this.formatPercent(this.percentData))
      const arr = []
      this.legendList.length && this.legendList.forEach((item, index) => {
        const { color } = item
        arr.push(
          [percentList[index], color]
        )
      })
      return arr
    },
    option () {
      const value = this.total
      const int = value.toFixed(2).split('.')[0]
      return {
        title: {
          text: '{a|' + int + '}\n{b|' + this.title + '}',
          x: '17%',
          y: 'center',
          textStyle: {
            rich: {
              a: {
                fontSize: 38,
                fontWeight: 900,
                color: '#009eff'
              },
              b: {
                fontSize: 18,
                color: '#ffffff',
                padding: [5, 0]
              }
            }
          }
        },
        series: [
          {
            type: 'gauge',
            radius: '52%',
            center: ['24%', '50%'],
            startAngle: '90',
            endAngle: '-269.9999',
            axisLine: {
              show: true,
              lineStyle: {
                color: [
                  [1, '#009EFF']
                ],
                width: 2,
                shadowColor: '#009EFF',
                shadowOffsetX: 0,
                shadowOffsetY: 0,
                shadowBlur: 20,
                opacity: 1
              }
            },
            axisTick: {
              show: false
            },
            splitLine: {
              show: false
            },
            axisLabel: {
              show: false
            },
            pointer: {
              show: false
            },
            detail: {
              show: false
            }
          },
          {
            type: 'gauge',
            radius: '48%',
            center: ['24%', '50%'],
            startAngle: '90',
            endAngle: '-269.9999',
            axisLine: {
              show: true,
              lineStyle: {
                color: [
                  [1, '#009EFF']
                ],
                width: 2,
                shadowColor: '#009EFF',
                shadowOffsetX: 0,
                shadowOffsetY: 0,
                shadowBlur: 20,
                opacity: 1
              }
            },
            axisTick: {
              show: false
            },
            splitLine: {
              show: false
            },
            axisLabel: {
              show: false
            },
            pointer: {
              show: false
            },
            detail: {
              show: false
            }
          },
          {
            type: 'gauge',
            radius: '45%',
            center: ['24%', '50%'],
            clockwise: true,
            startAngle: '90',
            endAngle: '-269.9999',
            splitNumber: 100,
            detail: {
              offsetCenter: [0, -20],
              formatter: ' '
            },
            pointer: {
              show: true
            },
            axisLine: {
              show: true,
              lineStyle: {
                // color: [
                //   [0.1, '#009eff'],
                //   [0.2, '#10c6cd'],
                //   [0.3, '#18e202'],
                //   [0.4, '#ffa11a'],
                //   [0.5, '#E8544B'],
                //   [0.6, '#6E55C9'],
                //   [1, '#A19C9C']
                // ],
                color: this.colorPercent,
                width: 36,
                shadowColor: '#009EFF',
                shadowOffsetX: 0,
                shadowOffsetY: 0,
                shadowBlur: 60,
                opacity: 1
              }
            },
            axisTick: {
              show: false
            },
            splitLine: {
              show: true,
              length: 36,
              lineStyle: {
                color: '#051F54',
                width: 2
              }
            },
            axisLabel: {
              show: false
            }
          },
          {
            type: 'pie',
            radius: ['28%', '31%'],
            center: ['24%', '50%'],
            hoverAnimation: false,
            startAngle: 200,
            itemStyle: {
              normal: {
                color: {
                  type: 'linear',
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [{
                    offset: 0,
                    color: '#1fd6d7' // 内环的颜色
                  }]
                }
              }
            },
            label: {
              show: false
            },
            data: [100]
          }
        ]
      }
    }
  },
  methods: {
    init () {
      if (this.chart) this.chart.clear()
      this.chart = this.$echarts.init(this.$refs.chart)
      this.chart && this.chart.setOption(this.option)
    },
    formatPercent (arr) {
      const temp = []
      const tempList = (arr.length && arr.map((el) => {
        return Math.ceil(+el) / 100
      }))
      tempList.length && tempList.forEach((item, index) => {
        // 0 本身
        if (!index) temp.push(item)
        // 最后一个 是1
        else if (index === (arr.length - 1)) temp.push(1)
        else {
          temp.push(this.sum(tempList.slice(0, index + 1)))
        }
      })
      return temp
    },
    sum (arr) {
      let total = 0
      arr.length && arr.forEach((item) => {
        total += -(-item)
      })
      return total
    }
  }
}
</script>

<style lang="less" scoped>
.chart-wrapper {
  position: relative;
  width: 100%;
  height: 100%;

  .legend-wrapper {
    padding: 30px 0;
    position: absolute;
    right: 0;
    top: 0;
    left: 50%;
    bottom: 0;
    display: flex;
    align-items: center;
    margin-left: -20px;
    overflow: hidden;

    &.legend-wrapper-long {
      top: 30px;
      bottom: 30px;
      box-sizing: border-box;
    }
  }

  .legend-list {

    .legend-item {
      display: flex;
      align-items: center;
      margin-bottom: 28px;
      font-size: 20px;
      color: #fff;

      .icon {
        flex: 0 0 24px;
        width: 24px;
        height: 24px;
        border-radius: 4px;
        margin-right: 15px;
      }

      .name {
        flex: 1;
        max-width: 80px;
        display: inline-block;
        margin-right: 15px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .percent {
        display: inline-block;
        width: 80px;
        margin-right: 15px;
      }

      .count {
        display: inline-block;
        text-align: right;
      }
    }
  }
}
</style>
