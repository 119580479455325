<template>
  <div class="chart" id="chart" ref="chart"></div>
</template>

<script>
import { barStep } from '../../config'

export default {
  name: 'pillar-single',
  props: {
    xData: {
      type: Array,
      default () {
        return []
      }
    },
    yData1: {
      type: Array,
      default () {
        return []
      }
    },
    yData2: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      startValue: 0,
      endValue: 33,
      timer: null
    }
  },
  computed: {
    option () {
      const yData2 = this.yData2.map((item) => {
        return -item
      })
      return {
        legend: {
          right: '5%',
          bottom: '1%',
          orient: 'vertical',
          itemWidth: 20,
          textStyle: {
            color: '#fff',
            fontSize: 20
          }
        },
        dataZoom: [
          {
            yAxisIndex: 0,
            type: 'slider',
            orient: 'vertical',
            start: this.startValue,
            end: this.endValue,
            right: -100
          },
          {
            yAxisIndex: 1,
            type: 'slider',
            orient: 'vertical',
            start: this.startValue,
            end: this.endValue,
            right: -100
          }
        ],
        grid: [
          {
            top: '10%',
            width: '40%',
            left: 35,
            gridIndex: 0
          },
          {
            top: '10%',
            right: '15%',
            left: '48%',
            gridIndex: 1
          }
        ],
        xAxis: [
          {
            type: 'value',
            gridIndex: 0,
            axisTick: { show: false, inside: false },
            axisLabel: { show: false },
            axisLine: {
              show: false,
              lineStyle: {
                color: '#000'
              }
            },
            splitLine: {
              show: false
            }
          },
          {
            type: 'value',
            gridIndex: 1,
            axisTick: { show: false },
            axisLine: {
              show: false,
              lineStyle: {
                color: '#000'
              }
            },
            axisLabel: {
              show: false
            },
            splitLine: {
              show: false
            }
          }
        ],
        yAxis: [
          {
            type: 'category',
            gridIndex: 0,
            inverse: true,
            data: this.xData,
            axisTick: { show: false },
            axisLabel: { show: false },
            axisLine: {
              show: false,
              lineStyle: {
                color: '#000'
              }
            }
          },
          {
            type: 'category',
            gridIndex: 1,
            inverse: true,
            data: this.xData,
            axisTick: { show: false },
            axisLine: {
              show: false
            },
            axisLabel: {
              textStyle: {
                color: '#fff',
                fontSize: 20
              }
            }
          }
        ],
        color: ['#2FACFA', '#F5A623'],
        series: [
          {
            name: '公用',
            type: 'bar',
            barWidth: '18',
            gridIndex: 0,
            label: {
              show: true,
              position: 'left',
              color: '#fff',
              fontSize: 20,
              formatter: function (v) {
                return (v.value * -1)
              }
            },
            itemStyle: {
              normal: {
                show: true,
                color: new this.$echarts.graphic.LinearGradient(1, 0, 0, 0, [
                  {
                    offset: 0,
                    color: '#0078FF'
                  },
                  {
                    offset: 1,
                    color: '#7BE16E'
                  }
                ]),
                barBorderRadius: 50,
                borderWidth: 0,
                borderColor: '#333'
              }
            },
            data: yData2
          },
          {
            name: '个人',
            type: 'bar',
            barWidth: '18',
            xAxisIndex: 1,
            yAxisIndex: 1,
            label: {
              show: true,
              color: '#fff',
              fontSize: 20,
              position: 'right',
              formatter: function (v) {
                return v.value
              }
            },
            itemStyle: {
              normal: {
                show: true,
                color: new this.$echarts.graphic.LinearGradient(1, 0, 0, 0, [
                  {
                    offset: 0,
                    color: '#FB4542'
                  },
                  {
                    offset: 1,
                    color: '#FBB342'
                  }
                ]),
                barBorderRadius: 50,
                borderWidth: 0,
                borderColor: '#333'
              }
            },
            data: this.yData1
          }
        ]
      }
    }
  },
  destroyed () {
    clearInterval(this.timer)
  },
  methods: {
    init () {
      const xLen = this.xData.length
      this.tieNum = 100 / xLen
      this.startValue = 100 - 100 / 5
      this.endValue = 100
      this.chart = this.$echarts.init(this.$refs.chart)
      this.chart && this.chart.setOption(this.option)
      this.circulation()
    },
    circulation () {
      if (this.timer) clearInterval(this.timer)
      this.timer = setInterval(() => {
        if (this.startValue <= 0) {
          this.startValue = 100 - 100 / 5
          this.endValue = 100
        } else {
          this.startValue -= this.tieNum
          this.endValue -= this.tieNum
        }
        this.chart = this.$echarts.init(this.$refs.chart)
        this.chart && this.chart.setOption(this.option)
      }, barStep * 1000)
    }
  }
}
</script>

<style lang="less" scoped>

</style>
