<template>
  <ul class="ev-list" v-if="list.length">
    <div style="overflow:hidden;">
      <vue-seamless-scroll
        :data="list"
        :class-option="defaultOption">
        <li class="ev-item" v-for="(item,index) in list" :key="index">
          <div class="ev-item-1">
            {{ item.province }}
            <span class="upload-line-h left-top"></span>
            <span class="upload-line-v left-top2"></span>
            <span class="upload-line-h left-bottom"></span>
            <span class="upload-line-v left-bottom2"></span>
            <span class="upload-line-h right-top"></span>
            <span class="upload-line-v right-top2"></span>
            <span class="upload-line-h right-bottom"></span>
            <span class="upload-line-v right-bottom2"></span>
          </div>
          <div class="ev-item-2">
            <div class="line-container">
              <p class="line-inner" :style="{width:item.percent}"></p>
            </div>
          </div>
          <p class="ev-item-3">{{ item.qty }}</p>
        </li>
      </vue-seamless-scroll>
    </div>
  </ul>
</template>

<script>
import vueSeamlessScroll from '../scroll-list'

export default {
  name: 'index',
  components: {
    vueSeamlessScroll
  },
  props: {
    list: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      defaultOption: {
        step: 0.5, // 数值越大速度滚动越快
        limitMoveNum: 2, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: false, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000 // 单步运动停止的时间(默认值1000ms)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.ev-list {
  box-sizing: border-box;
  padding: 20px 40px;
  height: 330px;
  background: url("../../assets/images/table-bg.png") no-repeat;
  background-size: 100% 100%;
  color: #00BCFD;
  overflow: hidden;

  .ev-item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 30px;

    .ev-item-1 {
      flex: 0 0 80px;
      position: relative;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #0B113F;
      font-size: 20px;

      .upload-line-v {
        position: absolute;
        height: 10px;
        width: 2px;
        background-color: #00BCFD;
      }

      .upload-line-h {
        position: absolute;
        height: 2px;
        width: 10px;
        background-color: #00BCFD;
      }

      .left-top {
        left: 0;
        top: 0;
      }

      .left-top2 {
        top: 0;
        left: 0;
      }

      .left-bottom {
        bottom: 0;
        left: 0;
      }

      .left-bottom2 {
        bottom: 0;
        left: 0;
      }

      .right-top {
        right: 0;
        top: 0;
      }

      .right-top2 {
        right: 0;
        top: 0;
      }

      .right-bottom {
        right: 0;
        bottom: 0;
      }

      .right-bottom2 {
        right: 0;
        bottom: 0;
      }
    }

    .ev-item-2 {
      flex: 1;
      text-align: center;
      padding: 0 20px;

      .line-inner {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 70%;
        background: linear-gradient(90deg, #0A4575 10%, #00A9F5 100%);
        border-radius: 8px;
      }

      .line-container {
        position: relative;
        height: 20px;
        width: 100%;
        background: linear-gradient(90deg, #1F4D6D 80%, #163451 100%);
        border-radius: 8px;
      }
    }

    .ev-item-3 {
      flex: 0 0 50px;
      text-align: right;
      font-size: 20px;
    }
  }
}
</style>
