<template>
  <screen-adapter :w="3840" :h="2160">
    <div class="login-page">
      <div class="login-header">
        <img class="login-header-img" src="../assets/images/login-header.png" alt="">
      </div>
      <div class="login-content">
        <div class="login-picture">
          <img class="login-picture-img" :src="require('../assets/images/login-img.png')">
        </div>
        <div class="login-form">
          <a-form-model ref="form" :model="model" :rules="validatorRules">
            <p class="login-title">
              <img class="login-title-img" src="../assets/images/login-title.png" alt="">
            </p>
            <a-form-model-item required prop="username">
              <a-input v-model="model.username" size="large" placeholder="请输入用户名">
                <a-icon slot="prefix" type="user" :style="{ color: 'rgba(0,0,0,.25)' }"/>
              </a-input>
            </a-form-model-item>
            <a-form-model-item required prop="password">
              <a-input v-model="model.password" size="large" type="password" autocomplete="false" placeholder="请输入密码">
                <a-icon slot="prefix" type="lock" :style="{ color: 'rgba(0,0,0,.25)' }"/>
              </a-input>
            </a-form-model-item>
            <a-row :gutter="0">
              <a-col :span="16">
                <a-form-model-item required prop="inputCode">
                  <a-input v-model="model.inputCode" size="large" type="text" placeholder="请输入验证码">
                    <a-icon slot="prefix" type="smile" :style="{ color: 'rgba(0,0,0,.25)' }"/>
                  </a-input>
                </a-form-model-item>
              </a-col>
              <a-col :span="8" style="text-align: right">
                <img class="random-img" v-if="requestCodeSuccess"
                     style="margin-top: 2px;"
                     :src="randCodeImage"
                     @click="handleChangeCheckCode"/>
                <img class="random-img" v-else
                     style="margin-top: 2px;"
                     :src="require('../assets/images/checkcode.png')"
                     @click="handleChangeCheckCode"/>
              </a-col>
            </a-row>
            <a-form-model-item class="auto-login">
              <a-checkbox @change="handleRememberMeChange" default-checked>自动登录</a-checkbox>
            </a-form-model-item>
            <a-form-item style="margin-top:24px">
              <a-button
                size="large"
                type="primary"
                htmlType="submit"
                class="login-btn"
                :loading="loginBtn"
                @click.stop.prevent="handleSubmit"
                :disabled="loginBtn">确定
              </a-button>
            </a-form-item>
          </a-form-model>
        </div>
      </div>
      <div class="login-footer">
        <a target="_blank" href="https://beian.miit.gov.cn/">
          <p class="login-footer-p">
            <img :src="require('../assets/images/bnZTvbHnlciHd0289dc0a46fc5b15b3363ffa78cf6c7_1640754774127.png')"
                 class="login-footer-img">
            <span style="display: inline-block; vertical-align: top; margin-left: 3px;">吉公网安备 22010602000615号</span>
            <span style="display: inline-block; vertical-align: top; margin-left: 15px;">吉ICP备2021009342号-1</span>
          </p>
        </a>
      </div>
    </div>
    <Link></Link>
  </screen-adapter>
</template>
<script>
import screenAdapter from '../components/screen-adapter'
import Link from '../components/link'
import { getRandomCode, postLogin } from '../api'
import { cache } from '../config'

export default {
  components: {
    screenAdapter,
    Link
  },
  data () {
    return {
      requestCodeSuccess: false,
      randCodeImage: '',
      currdatetime: '',
      loginBtn: false,
      model: {
        username: '',
        password: '',
        inputCode: '',
        rememberMe: true
      },
      validatorRules: {
        username: [
          { required: true, message: '请输入用户名!' }
        ],
        password: [{
          required: true, message: '请输入密码!', validator: 'click'
        }],
        inputCode: [{
          required: true, message: '请输入验证码!'
        }]
      }
    }
  },
  created () {
    this.handleChangeCheckCode()
  },
  methods: {
    handleRememberMeChange (e) {
      this.model.rememberMe = e.target.checked
    },
    handleChangeCheckCode () {
      this.currdatetime = new Date().getTime()
      this.model.inputCode = ''
      getRandomCode(this.currdatetime).then((res) => {
        if (res.success) {
          this.randCodeImage = res.result
          this.requestCodeSuccess = true
        } else {
          this.$message.error(res.message)
          this.requestCodeSuccess = false
        }
      }).catch(() => {
        this.requestCodeSuccess = false
      })
    },
    handleSubmit () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loginBtn = true
          const data = {
            username: this.model.username,
            password: this.model.password,
            captcha: this.model.inputCode,
            checkKey: this.currdatetime,
            remember_me: this.model.rememberMe
          }
          postLogin(data).then((res) => {
            if (res.success) {
              this.$message.success(res.message)
              this.$router.replace('/index')
              localStorage.setItem(cache.token, res.result.token)
            } else {
              this.$message.warning(res.message)
              localStorage.removeItem(cache.token)
            }
          }).finally(() => {
            this.loginBtn = false
            this.handleChangeCheckCode()
          })
        } else {
          return false
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.login-page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: stretch;

  .login-header {
    height: 342px;
    width: 100%;

    .login-header-img {
      width: 100%;
    }
  }
}

/*登录内容区域*/
.login-content {
  flex: 1;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.login-picture {
  .login-picture-img {
    width: 1580px;
    height: 1030px;
    text-align: center;
  }
}

.login-footer {
  margin: 50px 0;

  .login-footer-p {
    font-size: 30px;
    color: #fff;
    text-align: center;
    padding-right: 7%;

    .login-footer-img {
      display: inline-block;
      vertical-align: top;
      margin-right: 10px;
      width: 30px;
      height: 30px;
    }
  }
}

/*登录表单*/
.login-form {
  margin-right: 500px;
  width: 800px;
  height: 770px;
  padding: 100px 55px 0;
  background: url("../assets/images/login-form.png") no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .login-title {
    text-align: center;
    padding-bottom: 50px;

    .login-title-img {
      height: 50px;
    }
  }

  form {
    width: 100%;
    height: 100%;
    text-align: center;
  }

  /deep/ .ant-form-item {
    margin-bottom: 44px;
    position: relative;

    .ant-form-explain {
      position: absolute;
      left: 0;
      top: 74px;
      font-size: 20px;
      text-align: left;
    }
  }

  /deep/ .anticon {
    color: #57A3F9 !important;
    font-size: 38px;
  }

  /deep/ .ant-input-affix-wrapper .ant-input:not(:first-child) {
    padding-left: 60px;
  }

  /deep/ .ant-form-item-control {
    .ant-input {
      border-radius: 4px;
      border: 2px solid #56A4F9;
      background: transparent;
      color: #fff;
      height: 72px;
      line-height: 72px;
      box-sizing: border-box;
      font-size: 24px;

      &::-webkit-input-placeholder {
        color: #49A0E0;
      }

      &::-moz-placeholder {
        color: #49A0E0;
      }

      &:-moz-placeholder {
        color: #49A0E0;
      }

      &:-ms-input-placeholder {
        color: #49A0E0;
      }
    }
  }

  .random-img {
    height: 70px;
  }

  .auto-login {
    text-align: left;

    /deep/ .ant-checkbox-wrapper {
      color: #fff;
      font-size: 24px;

      .ant-checkbox {
        width: 24px;
        height: 24px;

        .ant-checkbox-inner {
          width: 24px;
          height: 24px;

          &::after {
            width: 7px;
            height: 14px;
          }
        }

        input {
          width: 24px;
          height: 24px;
        }
      }
    }
  }
}

.login-btn {
  width: 600px;
  height: 72px;
  line-height: 72px;
  background: #56A4F9;
  border-radius: 4px;
  font-size: 24px;
  font-weight: bold;
  color: #FFFFFF;
  text-align: center;
  cursor: pointer;
  transition: all 200ms;
}

</style>
