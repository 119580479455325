<template>
  <ul class="link-list" v-if="fullFlag">
    <li
      class="link-item"
      v-for="(item, index) in linkList"
      :key="index"
      @click="handleGo(item.linkUrl)"
    >
      <i class="iconfont" :class="item.icon"></i>
      <p class="link-item-name">{{ item.name }}</p>
    </li>
    <li class="link-item link-item-toggle" @click="toggle">
      <i class="iconfont icon-arr-right"></i>
      <p class="link-item-name">收起</p>
    </li>
  </ul>
  <ul class="link-list small" v-else>
    <li
      class="link-item"
      v-for="(item, index) in linkList"
      :key="index"
      @click="handleGo(item.linkUrl)"
    >
      <i class="iconfont" :class="item.icon"></i>
      <p class="link-item-name">{{ item.name }}</p>
    </li>
    <li class="link-item link-item-toggle" @click="toggle">
      <i class="iconfont icon-arr-left"></i>
    </li>
  </ul>
</template>

<script>
export default {
  data () {
    return {
      fullFlag: true,
      linkList: [
        {
          name: '电池回收',
          icon: 'icon-battery-recover',
          linkUrl: 'https://admin.fawersmart.com/'
        },
        {
          name: '后市场服务',
          icon: 'icon-battery-service',
          linkUrl: null
        },
        {
          name: '储能备电',
          icon: 'icon-battery-energy-storage',
          linkUrl: null
        },
        {
          name: '小动力电池',
          icon: 'icon-battery-power',
          linkUrl: null
        },
        {
          name: '租赁运营',
          icon: 'icon-rent',
          linkUrl: 'https://rent.fawersmart.com/'
        },
        {
          name: '云仓中心',
          icon: 'icon-battery-cloud',
          linkUrl: 'https://big.fawersmart.com/'
        }
      ]
    }
  },
  methods: {
    handleGo (url) {
      if (url) window.open(url)
      else {
        this.$message.warning('暂未开放！')
      }
    },
    toggle () {
      this.fullFlag = !this.fullFlag
    }
  }
}
</script>

<style scoped lang="less">
.link-list {
  position: fixed;
  right: 0;
  top: calc(50% - 300px);
  z-index: 999;

  &.small {
    top: calc(50% - 150px);

    .link-item {
      position: relative;
      width: 80px;
      height: 80px;
      opacity: 0.9;

      &:hover {
        opacity: 1;

        .link-item-name {
          width: 160px;
          transition: width 0.2s ease-out 0s;
        }
      }

      .iconfont {
        display: inline-block;
        margin-bottom: 0;
        padding-top: 0;
        font-size: 40px;
        color: #fff;
      }

      .link-item-name {
        position: absolute;
        right: 80px;
        height: 80px;
        width: 0;
        text-align: center;
        line-height: 80px;
        background: rgba(86, 164, 249, 0.6);
        font-size: 28px;
        margin-top: 0;
        overflow: hidden;
        margin-bottom: 0;
      }
    }
  }

  .link-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 140px;
    height: 140px;
    background: #56A4F9;
    margin-top: 1px;
    opacity: 0.9;

    &:hover {
      cursor: pointer;
      color: #fff;
      opacity: 1;
    }

    .iconfont {
      display: inline-block;
      margin-bottom: 16px;
      font-size: 48px;
      color: #fff;
    }

    .link-item-name {
      display: inline-block;
      font-size: 24px;
      color: #fff;
    }
  }
}
</style>
