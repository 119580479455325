<template>
  <div class="chart" id="chart" ref="chart"></div>
</template>

<script>
import { barStep } from '../../config'

export default {
  name: 'pillar-single',
  props: {
    xData: {
      type: Array,
      default () {
        return []
      }
    },
    yData: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      startValue: 0,
      endValue: 33,
      timer: null
    }
  },
  computed: {
    option () {
      return {
        grid: {
          top: '10%',
          left: '0',
          bottom: '5%',
          right: '5%',
          containLabel: true
        },
        dataZoom: [
          {
            type: 'slider',
            start: this.startValue,
            end: this.endValue,
            bottom: -100
          }
        ],
        xAxis: [
          {
            type: 'category',
            data: this.xData,
            axisTick: {
              alignWithLabel: true
            },
            axisLine: {
              show: true
            },
            axisLabel: {
              textStyle: {
                color: '#fff',
                fontSize: 20
              },
              margin: 20
            },
            interval: 1
          }
        ],
        yAxis: [
          {
            show: true,
            type: 'value',
            splitLine: {
              show: true,
              lineStyle: {
                color: '#2d454b',
                width: 1
              }
            },
            axisLabel: {
              textStyle: {
                color: '#fff',
                fontSize: 20
              },
              margin: 20
            },
            name: '辆',
            nameTextStyle: {
              color: '#fff',
              fontSize: 20
            }
          }
        ],
        series: [
          {
            name: '上部圆',
            type: 'pictorialBar',
            silent: true,
            symbolSize: [30, 10],
            symbolOffset: [0, -6],
            symbolPosition: 'end',
            z: 12,
            label: {
              normal: {
                show: true,
                position: 'top',
                fontSize: 15,
                fontWeight: 'bold',
                color: '#009eff'
              }
            },
            color: '#009eff',
            data: this.yData
          },
          {
            name: '底部圆',
            type: 'pictorialBar',
            silent: true,
            symbolSize: [30, 10],
            symbolOffset: [0, 7],
            z: 12,
            color: '#009eff',
            data: this.yData
          },
          {
            name: '数量',
            type: 'bar',
            barWidth: '30',
            barGap: '10%', // Make series be overlap
            barCateGoryGap: '10%',
            itemStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(0, 0, 1, 0, [
                  {
                    offset: 0,
                    color: 'rgba(0,158,255,0.7)' // 0% 处的颜色
                  },
                  {
                    offset: 0.25,
                    color: 'rgba(0,158,255,0.3)' // 100% 处的颜色
                  },
                  {
                    offset: 0.75,
                    color: 'rgba(0,158,255,0.3)' // 100% 处的颜色
                  },
                  {
                    offset: 1,
                    color: 'rgba(0,158,255,0.7)' // 0% 处的颜色
                  }
                ]),
                opacity: 0.8
              }
            },
            data: this.yData
          }
        ]
      }
    }
  },
  destroyed () {
    clearInterval(this.timer)
  },
  methods: {
    init () {
      const xLen = this.xData.length
      this.tieNum = 100 / xLen
      this.endValue = 100 / 3
      this.chart = this.$echarts.init(this.$refs.chart)
      this.chart && this.chart.setOption(this.option)
      this.circulation()
    },
    circulation () {
      if (this.timer) clearInterval(this.timer)
      this.timer = setInterval(() => {
        if (this.endValue >= 100) {
          this.startValue = 0
          this.endValue = 100 / 3
        } else {
          this.startValue += this.tieNum
          this.endValue += this.tieNum
        }
        this.chart = this.$echarts.init(this.$refs.chart)
        this.chart && this.chart.setOption(this.option)
      }, barStep * 1000)
    }
  }
}
</script>

<style lang="less" scoped>

</style>
