<template>
  <screen-adapter>
    <div class="page" v-if="!loading">
      <div class="page-h">
        <div class="page-h-h">
          <index1></index1>
        </div>
        <div class="page-h-h">
          <index3></index3>
        </div>
      </div>
      <div class="page-h">
        <div class="page-h-h">
          <index2></index2>
        </div>
        <div class="page-h-h">
          <index4></index4>
        </div>
      </div>
    </div>
  </screen-adapter>
</template>
<script>
import screenAdapter from '../components/screen-adapter'
import index1 from './index1'
import index2 from './index2'
import index3 from './index3'
import index4 from './index4'
import { cache } from '../config'

export default {
  components: {
    screenAdapter,
    index1,
    index2,
    index3,
    index4
  },
  data () {
    return {
      loading: true
    }
  },
  created () {
    const token = localStorage.getItem(cache.token)
    if (!token) {
      this.$router.push('/login')
    }
    this.loading = false
  }
}
</script>
<style lang="less" scoped>
.page {
  width: 100%;
  height: 100%;

  .page-h {
    width: 100%;
    height: 50%;
  }

  .page-h-h {
    display: inline-block;
    vertical-align: top;
    width: 50%;
    height: 100%;
  }
}
</style>
