<template>
  <div class="statistics" v-if="data.length">
    <ul class="list">
      <li class="item" v-for="(item,index) in list" :key="index">
        <p class="value" :style="{color:item.color}">
          <!--          <span class="count">{{ item.value }}</span>-->
          <count-to
            :ref="`count${index}`"
            class="count"
            :startVal="startVal"
            :duration="2000"
            :endVal="item.value"></count-to>
          <span class="unit">{{ item.unit }}</span>
        </p>
        <p class="name">{{ item.name }}</p>
        <img class="image" :src="item.img" alt="">
      </li>
    </ul>
  </div>
</template>

<script>
import countTo from 'vue-count-to'

export default {
  name: 'index',
  components: {
    countTo
  },
  props: {
    data: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      startVal: 0
    }
  },
  computed: {
    list () {
      const defaultArr = [
        {
          value: '0',
          color: '#00FEE3',
          unit: 'kWh',
          name: '总电量',
          img: require('../../assets/images/icon1.png')
        },
        {
          value: '0',
          color: '#61C9FF',
          unit: 'KG',
          name: '总重量',
          img: require('../../assets/images/icon2.png')
        },
        {
          value: '0',
          color: '#FFBD43',
          unit: '个',
          name: '总个数',
          img: require('../../assets/images/icon3.png')
        }
      ]
      this.data.length && defaultArr.forEach((item, index) => {
        item.value = this.data[index].value
        item.name = this.data[index].name
      })
      return defaultArr
    }
  },
  methods: {
    reset () {
    }
  }
}
</script>

<style lang="less" scoped>
@keyframes breath_light1 {
  0% {
    filter: brightness(100%);
  }
  33% {
    filter: brightness(200%);
  }
  66% {
    filter: brightness(100%);
  }
  100% {
    filter: brightness(100%);
  }
}

@keyframes breath_light2 {
  0% {
    filter: brightness(100%);
  }
  33% {
    filter: brightness(100%);
  }
  66% {
    filter: brightness(200%);
  }
  100% {
    filter: brightness(100%);
  }
}

@keyframes breath_light3 {
  0% {
    filter: brightness(100%);
  }
  33% {
    filter: brightness(100%);
  }
  66% {
    filter: brightness(100%);
  }
  100% {
    filter: brightness(200%);
  }
}

.statistics {
  padding-top: 40px;

  .list {
    display: flex;

    .item {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &:first-child {
        .image {
          animation: breath_light1 6s ease infinite;
        }
      }

      &:last-child {
        .image {
          animation: breath_light3 6s ease infinite;
        }
      }

      .image {
        display: inline-block;
        width: 147px;
        height: 192px;
        animation: breath_light2 6s ease infinite;
      }

      .name {
        padding: 15px 0 30px;
        font-size: 26px;
        color: #BCE4FF;
        font-weight: bold;
      }

      .value {
        font-weight: bold;

        .count {
          font-size: 42px;
        }

        .unit {
          font-size: 20px;
        }
      }
    }
  }
}
</style>
