<template>
  <div class="header">
    <img class="image" :src="bgImg" alt="" @click="handleClick">
  </div>
</template>

<script>
export default {
  name: 'index',
  props: {
    bgImg: {
      type: String,
      default: ''
    }
  },
  methods: {
    handleClick () {
      this.$emit('on-click')
    }
  }
}
</script>

<style lang="less" scoped>
.header {
  width: 100%;
  height: 168px;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
  }

  .image {
    width: 100%;
    height: 100%;
  }
}
</style>
