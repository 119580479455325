<template>
  <div class="c-title">
    <img class="c-title-l" src="../../assets/images/title-l.png" alt="">
    <div class="title-content">
      <p class="title">{{ title }}</p>
      <!--      <p class="title-en">{{ titleEn }}</p>-->
    </div>
    <img class="c-title-r" src="../../assets/images/title-r.png" alt="">
  </div>
</template>

<script>
export default {
  name: 'c-title',
  props: {
    title: {
      type: String,
      default: ''
    },
    titleEn: {
      type: String,
      default: ''
    },
    width: {
      type: Number,
      default: 710
    }
  }
}
</script>

<style lang="less" scoped>
.c-title {
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
  }

  .title-content {
    display: inline-block;
    font-weight: bold;
    font-size: 58px;
    font-family: '微软雅黑';
    position: relative;

    .title {
      padding: 0 50px 0;
      text-align: center;
      color: #65CCFF;
      font-weight: bold;
    }

    //.title-en {
    //  padding: 0 50px 0;
    //  margin-bottom: -60px;
    //  text-align: center;
    //  color: #585858;
    //  font-weight: bold;
    //}
  }
}
</style>
