<template>
  <div class="chart" id="chart" ref="chart"></div>
</template>

<script>
export default {
  name: 'pillar-single',
  props: {
    xData: {
      type: Array,
      default () {
        return []
      }
    },
    yData1: {
      type: Array,
      default () {
        return []
      }
    },
    yData2: {
      type: Array,
      default () {
        return []
      }
    },
    yData3: {
      type: Array,
      default () {
        return []
      }
    }
  },
  computed: {
    option () {
      return {
        grid: {
          top: '10%',
          left: '0',
          bottom: '5%',
          right: '20%',
          containLabel: true
        },
        legend: {
          right: '5%',
          bottom: '5%',
          orient: 'vertical',
          itemWidth: 28,
          textStyle: {
            color: '#fff',
            fontSize: 22
          },
          data: ['安特智行', '移动出行', '成都鸿瑞']
        },
        xAxis: [
          {
            type: 'category',
            data: this.xData,
            axisTick: {
              alignWithLabel: true
            },
            axisLine: {
              show: true
            },
            axisLabel: {
              textStyle: {
                color: '#fff',
                fontSize: 20
              },
              margin: 20
            },
            interval: 1
          }
        ],
        yAxis: [
          {
            show: true,
            type: 'value',
            splitLine: {
              show: true,
              lineStyle: {
                color: '#2d454b',
                width: 1
              }
            },
            axisLabel: {
              textStyle: {
                color: '#fff',
                fontSize: 20
              }
            },
            name: '辆',
            nameTextStyle: {
              color: '#fff',
              fontSize: 20
            }
          }
        ],
        series: [
          // 1
          {
            name: '上部圆',
            type: 'pictorialBar',
            silent: true,
            symbolSize: [30, 10],
            symbolOffset: [-45, -6],
            symbolPosition: 'end',
            z: 10,
            label: {
              normal: {
                show: true,
                position: 'top',
                offset: [-45, -6],
                fontSize: 18,
                fontWeight: 'bold',
                color: '#fff'
              }
            },
            color: '#009eff',
            data: this.yData1
          },
          {
            name: '底部圆',
            type: 'pictorialBar',
            silent: true,
            symbolSize: [30, 10],
            symbolOffset: [-45, 7],
            z: 10,
            color: '#009eff',
            data: this.yData1
          },
          {
            name: '安特智行',
            type: 'bar',
            barWidth: '30',
            barGap: '50%', // Make series be overlap
            barCateGoryGap: '10%',
            itemStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(0, 0, 1, 0, [
                  {
                    offset: 0,
                    color: 'rgba(0,158,255,0.7)' // 0% 处的颜色
                  },
                  {
                    offset: 0.25,
                    color: 'rgba(0,158,255,0.3)' // 100% 处的颜色
                  },
                  {
                    offset: 0.75,
                    color: 'rgba(0,158,255,0.3)' // 100% 处的颜色
                  },
                  {
                    offset: 1,
                    color: 'rgba(0,158,255,0.7)' // 0% 处的颜色
                  }
                ]),
                opacity: 0.8
              }
            },
            data: this.yData1
          },
          // 2
          {
            name: '上部圆',
            type: 'pictorialBar',
            silent: true,
            symbolSize: [30, 10],
            symbolOffset: [0, -6],
            symbolPosition: 'end',
            z: 11,
            label: {
              normal: {
                show: true,
                position: 'top',
                fontSize: 15,
                fontWeight: 'bold',
                color: '#fff'
              }
            },
            color: '#ffa11a',
            data: this.yData2
          },
          {
            name: '底部圆',
            type: 'pictorialBar',
            silent: true,
            symbolSize: [30, 10],
            symbolOffset: [0, 7],
            z: 11,
            color: '#ffa11a',
            data: this.yData2
          },
          {
            name: '移动出行',
            type: 'bar',
            barWidth: '30',
            barGap: '50%', // Make series be overlap
            barCateGoryGap: '10%',
            itemStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(0, 0, 1, 0, [
                  {
                    offset: 0,
                    color: 'rgba(255,161,26,0.7)' // 0% 处的颜色
                  },
                  {
                    offset: 0.25,
                    color: 'rgba(255,161,26,0.3)' // 100% 处的颜色
                  },
                  {
                    offset: 0.75,
                    color: 'rgba(255,161,26,0.3)' // 100% 处的颜色
                  },
                  {
                    offset: 1,
                    color: 'rgba(255,161,26,0.7)' // 0% 处的颜色
                  }
                ]),
                opacity: 0.8
              }
            },
            data: this.yData2
          },
          // 3
          {
            name: '上部圆',
            type: 'pictorialBar',
            silent: true,
            symbolSize: [30, 10],
            symbolOffset: [45, -6],
            symbolPosition: 'end',
            z: 12,
            label: {
              normal: {
                show: true,
                position: 'top',
                offset: [45, -6],
                fontSize: 15,
                fontWeight: 'bold',
                color: '#fff'
              }
            },
            color: '#fb4542',
            data: this.yData3
          },
          {
            name: '底部圆',
            type: 'pictorialBar',
            silent: true,
            symbolSize: [30, 10],
            symbolOffset: [45, 7],
            z: 12,
            color: '#fb4542',
            data: this.yData3
          },
          {
            name: '成都鸿瑞',
            type: 'bar',
            barWidth: '30',
            barGap: '50%', // Make series be overlap
            barCateGoryGap: '10%',
            itemStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(0, 0, 1, 0, [
                  {
                    offset: 0,
                    color: 'rgba(251,69,66,0.7)' // 0% 处的颜色
                  },
                  {
                    offset: 0.25,
                    color: 'rgba(251,69,66,0.3)' // 100% 处的颜色
                  },
                  {
                    offset: 0.75,
                    color: 'rgba(251,69,66,0.3)' // 100% 处的颜色
                  },
                  {
                    offset: 1,
                    color: 'rgba(251,69,66,0.7)' // 0% 处的颜色
                  }
                ]),
                opacity: 0.8
              }
            },
            data: this.yData3
          }
        ]
      }
    }
  },
  methods: {
    init () {
      if (this.chart) this.chart.clear()
      this.chart = this.$echarts.init(this.$refs.chart)
      this.chart && this.chart.setOption(this.option)
    }
  }
}
</script>

<style lang="less" scoped>

</style>
