<template>
  <div class="index4">
    <div class="index4-header-title" @click="$router.push('/index4')">
      <c-title
        title="制造链&设备信息"
        title-en="MANUFACTURING CHAIN & EQUIPMENT INFORMATION"
        :width="1850"
      ></c-title>
    </div>
    <div class="index4-content">
      <div class="index4-content-l">
        <div class="index4-content-t swiper-no-swiping">
          <div class="swiper swiper-top">
            <div class="swiper-wrapper" ref="swiperTop">
              <div class="swiper-slide" v-for="(item,index) in list" :key="index">
                <video
                  muted
                  :ref="`swiperTopVideo${index}`"
                  class="swiper-top-img"
                  :src="item.picUrl"
                  width="100%"
                  height="100%"
                  style="border-radius: 10px"
                  @ended="handleEnd"
                >
                  您的浏览器不支持video标签!
                </video>
              </div>
            </div>
          </div>
        </div>
        <div class="index4-content-b swiper-no-swiping">
          <div class="swiper swiper-bottom">
            <div class="swiper-wrapper">
              <div class="swiper-slide" v-for="(item,index) in list" :key="index">
                <video
                  muted
                  class="swiper-bottom-image"
                  :src="item.picUrl"
                  disabled="true"
                  width="100%"
                  height="100%"
                  style="border-radius: 10px">
                  您的浏览器不支持video标签!
                </video>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="index4-content-r">
        <p class="title"> {{ title }}</p>
        <vue-typed-js v-if="showTyped" :strings="[desc]">
          <div class="content">
            <span class="typing"></span>
          </div>
        </vue-typed-js>
      </div>
    </div>
  </div>
</template>
<script>
/*eslint-disable*/
import Swiper from 'swiper/swiper-bundle'
import cTitle from '../components/c-title'
import { getDeviceData } from '../api'

export default {
  components: {
    cTitle
  },
  data() {
    return {
      swiper: null,
      content: {},
      title: '',
      list: [],
      activeIndex: 0,
      showTyped: false
    }
  },
  computed: {
    desc() {
      return (this.list.length && this.list[this.activeIndex].desc) || ''
    }
  },
  watch: {
    desc(val) {
      if (val) {
        this.showTyped = false
        this.$nextTick(() => {
          this.showTyped = true
        })
      }
    }
  },
  created() {
    this.getDeviceData()
  },
  methods: {
    handleEnd() {
      if (this.activeIndex >= (this.list.length - 1)) {
        this.activeIndex = 0
      } else {
        this.activeIndex += 1
      }
      this.swiperTop.slideTo(this.activeIndex)
      this.swiperBottom.slideTo(this.activeIndex)
      this.$nextTick(() => {
        this.$refs[`swiperTopVideo${this.activeIndex}`][0].play()
        this.title = this.list[this.activeIndex].title
      })
    },
    getDeviceData() {
      this.list = []
      getDeviceData().then(({ result }) => {
        // result.forEach((item, index) => {
        //   item.picUrl = require(`../assets/video/video${index + 1}.mp4`)
        // })
        this.list = result
        this.$nextTick(() => {
          this.init()
        })
      })
    },
    init() {
      this.swiperBottom = new Swiper('.swiper-bottom', {
        spaceBetween: 10,
        slidesPerView: 3,
        freeMode: true,
        watchSlidesProgress: true,
        initialSlide: this.activeIndex
      })
      /* eslint-disable */
      this.swiperTop = new Swiper('.swiper-top', {
        spaceBetween: 10,
        thumbs: {
          swiper: this.swiperBottom
        },
        initialSlide: this.activeIndex
      })
      this.$nextTick(() => {
        this.list.forEach((item, index) => {
          this.$refs[`swiperTopVideo${index}`][0].load()
        })
        const videoPlay = this.$refs[`swiperTopVideo${this.activeIndex}`][0]
        videoPlay.play()
        this.title = this.list[this.activeIndex].title
      })
    }
  }
}
</script>
<style lang="less" scoped>
@import "~swiper/swiper-bundle.min.css";

.swiper {
  width: 100%;
  height: 100%;

  &.swiper-bottom {
    .swiper-slide {
      opacity: 0.4;

      &.swiper-slide-thumb-active {
        opacity: 1;
      }
    }
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;

    .swiper-top-img {
      display: block;
      width: 100%;
      height: 100%;
    }

    .swiper-bottom-image {
      display: block;
      width: 100%;
      height: 100%;
      padding: 19px 26px 31px 23px;
      object-fit: cover;
      clip-path: polygon(52px 0, calc(100% - 72px) 0, 100% 62px,
      100% calc(100% - 72px), calc(100% - 72px) 100%,
      52px 100%, 0 calc(100% - 86px), 0 52px);
    }
  }
}

.index4 {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: url('../assets/images/bg1.png') no-repeat;
  background-size: cover;

  .index4-header-title {
    padding-top: 50px;
  }

  .index4-content {
    position: relative;
    flex: 1;
    padding: 27px 130px;
    display: flex;

    .index4-content-l {
      flex: 0 0 2391px;
      width: 2391px;
      margin-right: 30px;

      .index4-content-t {
        box-sizing: border-box;
        width: 2391px;
        height: 1480px;
        background: url("../assets/images/zz-bg.png") no-repeat;
        padding: 60px 80px 90px;
        position: relative;
        overflow: hidden;

        .swiper-top {
          //border-radius: 110px;
          overflow: hidden;
          clip-path: polygon(52px 0, calc(100% - 62px) 0, 100% 48px,
          100% calc(100% - 42px), calc(100% - 52px) 100%,
          52px 100%, 0 calc(100% - 52px), 0 52px);
        }
      }

      .index4-content-b {
        box-sizing: border-box;
        padding-top: 30px;
        height: 500px;


        .swiper-slide {
          display: inline-block;
          width: 774px;
          height: 485px;
          background: url("../assets/images/zz-img.png") no-repeat;
          background-size: 100% 100%;
          //margin-right: 30px;
          overflow: hidden;

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }

    .index4-content-r {
      flex: 0 0 1211px;
      width: 1211px;
      height: 1998px;
      background: url("../assets/images/zz-info.png") no-repeat;
      background-size: 100% 100%;

      .title {
        font-size: 56px;
        color: #65CCFF;
        text-align: center;
        padding: 140px 0 60px;
        font-weight: bold;
      }

      .content {
        font-size: 42px;
        font-weight: 600;
        line-height: 2;
        color: #D4EAF6;
        padding: 0 90px 0 100px;
        text-indent: 2em;
      }
    }
  }
}
</style>
