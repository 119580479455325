<template>
  <div class="chart" id="chart" ref="chart"></div>
</template>

<script>
export default {
  name: 'pillar-single',
  props: {
    total: {
      type: Number,
      default: 0
    },
    offCount: {
      type: Number,
      default: 0
    },
    onCount: {
      type: Number,
      default: 0
    }
  },
  computed: {
    option () {
      const colorLeft = '#07dbff'
      const colorRight = '#206ed4'
      const colorLeftAlpha = ['rgba(33,148,216,0.2)', 'rgba(33,148,216,0.2)']
      const colorRightAlpha = ['rgba(32,110,212,0.2)', 'rgba(32,110,212,0.2)']
      const data = {
        value: this.onCount,
        percent: (this.onCount / this.total).toFixed(2)
      }
      return {
        title: [
          {
            text: '总数: ',
            top: 0,
            right: 200,
            textStyle: {
              fontSize: 34,
              color: '#fff'
            }
          },
          {
            text: `${this.total}个`,
            top: 0,
            right: 100,
            textStyle: {
              fontSize: 30,
              color: '#07DBFF'
            }
          },
          {
            text: '在线设备',
            bottom: '14%',
            left: '10%',
            textStyle: {
              fontSize: 22,
              color: '#fff'
            }
          },
          {
            text: `${this.onCount}个`,
            bottom: '24%',
            left: '13%',
            textStyle: {
              fontSize: 22,
              color: colorLeft
            }
          },
          {
            text: '离线设备',
            bottom: '14%',
            left: '70%',
            textStyle: {
              fontSize: 22,
              color: '#fff'
            }
          },
          {
            text: `${this.offCount}个`,
            bottom: '24%',
            left: '70%',
            textStyle: {
              fontSize: 22,
              color: colorRight
            }
          }
        ],
        series: [
          // 外侧灰色轴线
          {
            type: 'gauge',
            radius: '98%', // 位置
            center: ['45%', '85%'],
            min: 0,
            max: 100,
            startAngle: 180,
            endAngle: 0,
            axisLine: {
              show: true,
              lineStyle: {
                // 轴线样式
                width: 6, // 白线宽度
                color: [
                  [1, 'rgba(255,255,255,0.3)']
                ] // 颜色
              }
            },
            axisTick: {
              // 刻度
              show: false
            },
            splitLine: {
              // 分割线
              show: false
            },
            axisLabel: {
              // 刻度标签
              show: false
            },
            pointer: {
              // 仪表盘指针
              show: false
            },
            detail: {
              // 仪表盘详情
              show: false
            }
          },
          // 内侧刻度
          {
            name: '离线设备',
            type: 'gauge',
            radius: '90%', // 位置
            center: ['45%', '85%'],
            min: 0,
            max: 100,
            startAngle: 180,
            endAngle: 0,
            axisLine: {
              show: true,
              lineStyle: {
                // 轴线样式
                width: 2, // 宽度
                color: [
                  [
                    data.percent,
                    new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      {
                        offset: 0,
                        color: colorLeftAlpha[0]
                      },
                      {
                        offset: 1,
                        color: colorLeftAlpha[1]
                      }
                    ])
                  ],
                  [
                    1,
                    new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      {
                        offset: 0,
                        color: colorRightAlpha[0]
                      },
                      {
                        offset: 1,
                        color: colorRightAlpha[1]
                      }
                    ])
                  ]
                ] // 颜色
              }
            },
            axisTick: {
              // 内刻度
              show: true,
              splitNumber: 5,
              length: 25
            },
            splitLine: {
              // 分割线
              show: true,
              length: 35,
              lineStyle: {
                color: '#fff',
                width: 4
              }
            },
            axisLabel: {
              // 刻度标签
              show: false
            },
            pointer: {
              // 仪表盘指针
              show: false
            },
            detail: {
              // 仪表盘详情
              show: false
            }
          },
          // 中间白色半圆
          {
            type: 'gauge',
            radius: '35%', // 位置
            center: ['45%', '85%'],
            min: 0,
            max: 100,
            startAngle: 180,
            endAngle: 0,
            axisLine: {
              show: true,
              lineStyle: {
                // 轴线样式
                width: 120, // 宽度
                color: [
                  [
                    1,
                    new this.$echarts.graphic.RadialGradient(0.5, 1, 1, [
                      {
                        offset: 1,
                        color: 'rgba(229, 229, 229,0.15)'
                      },
                      {
                        offset: 0.72,
                        color: 'rgba(229, 229, 229,0.05)'
                      },
                      {
                        offset: 0.7,
                        color: 'rgba(229, 229, 229,0.4)'
                      },
                      {
                        offset: 0.401,
                        color: 'rgba(229, 229, 229,0.05)'
                      },
                      {
                        offset: 0.4,
                        color: 'rgba(229, 229, 229,0.8)'
                      },
                      {
                        offset: 0,
                        color: 'rgba(229, 229, 229,0.8)'
                      }
                    ])
                  ]
                ] // 颜色
              }
            },
            axisTick: {
              // 刻度
              show: false
            },
            splitLine: {
              // 分割线
              show: false
            },
            axisLabel: {
              // 刻度标签
              show: false
            },
            pointer: {
              // 仪表盘指针
              show: false
            },
            detail: {
              // 仪表盘详情
              show: false
            }
          },
          // 内侧轴线
          {
            type: 'gauge',
            radius: '90%', // 位置
            center: ['45%', '85%'],
            min: 0,
            max: 100,
            startAngle: 180,
            endAngle: 0,
            axisLine: {
              show: true,
              lineStyle: {
                // 轴线样式
                width: 20, // 宽度
                color: [
                  [data.percent, colorLeft],
                  [1, colorRight]
                ] // 颜色

              }
            },
            pointer: {
              // 仪表盘指针
              show: false
            },
            axisTick: {
              // 刻度
              show: false
            },
            splitLine: {
              // 分割线
              show: false
            },
            axisLabel: {
              // 刻度标签
              show: false
            },
            detail: {
              // 仪表盘详情
              show: false
            }
          },
          // 指针
          {
            name: '系列1',
            type: 'gauge',
            radius: '80%', // 位置
            center: ['45%', '85%'],
            min: 0,
            max: this.total,
            startAngle: 180,
            endAngle: 0,
            axisLine: {
              show: false
            },
            data: [
              {
                value: data.value
              }
            ],
            pointer: {
              // 仪表盘指针
              show: true
            },
            itemStyle: {
              color: 'rgba(255,255,255,0)',
              borderColor: '#f3f5f6',
              borderWidth: '4',
              borderType: 'solid'
            },
            axisTick: {
              // 刻度
              show: false
            },
            splitLine: {
              // 分割线
              show: false
            },
            axisLabel: {
              // 刻度标签
              show: false
            },
            detail: {
              // 仪表盘详情
              show: false
            }
          }
        ]
      }
    }
  },
  methods: {
    init () {
      if (this.chart) this.chart.clear()
      this.chart = this.$echarts.init(this.$refs.chart)
      this.chart && this.chart.setOption(this.option)
    }
  }
}
</script>

<style lang="less" scoped>

</style>
