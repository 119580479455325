import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import * as echarts from 'echarts'
import 'echarts-gl'
// import 'ant-design-vue/dist/antd.less'
import 'ant-design-vue/dist/antd.css'
import '../src/style/index.less'
import highcharts from 'highcharts'
import highcharts3d from 'highcharts/highcharts-3d'
import Antd from 'ant-design-vue'
import VueTypedJs from '../src/assets/js/typed'

highcharts3d(highcharts)

Vue.config.productionTip = false

Vue.prototype.$echarts = echarts

Vue.use(Antd)
Vue.use(VueTypedJs)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
