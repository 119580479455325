<template>
  <div class="chart" id="chart" ref="chart"></div>
</template>

<script>
export default {
  name: 'pillar-single',
  props: {
    percent: {
      type: [Number, String],
      default: 0
    },
    title: {
      type: String,
      default: '设备总使用率'
    }
  },
  computed: {
    option () {
      return {
        title: [
          {
            text: `${this.percent}%`,
            left: 'center',
            top: 'center',
            textStyle: {
              fontSize: '56',
              color: '#FFFFFF',
              fontFamily: 'DINAlternate-Bold, DINAlternate',
              fontWeight: '600'
            }
          },
          {
            text: this.title,
            left: 'center',
            bottom: 0,
            textStyle: {
              fontSize: 30,
              color: '#FFFFFF',
              fontWeight: '600'
            }
          }
        ],
        polar: {
          radius: ['52%', '62%'],
          center: ['50%', '50%']
        },
        angleAxis: {
          max: 100,
          show: false
        },
        radiusAxis: {
          type: 'category',
          show: true,
          axisLabel: {
            show: false
          },
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          }
        },
        series: [
          {
            name: '',
            type: 'bar',
            roundCap: true,
            barWidth: 90,
            showBackground: true,
            backgroundStyle: {
              color: 'rgba(0, 120,255, 0.3)'
            },
            data: [this.percent],
            coordinateSystem: 'polar',
            itemStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(0, 1, 0, 0, [
                  {
                    offset: 0,
                    color: '#0078ff'
                  },
                  {
                    offset: 1,
                    color: '#7be16e'
                  }
                ])
              }
            }
          },
          {
            name: '',
            type: 'pie',
            startAngle: 80,
            radius: ['66%'],
            hoverAnimation: false,
            center: ['50%', '50%'],
            itemStyle: {
              color: 'rgba(66, 66, 66, .1)',
              borderWidth: 1,
              borderColor: '#0078ff'
            },
            data: [100]
          },
          {
            name: '',
            type: 'pie',
            startAngle: 80,
            radius: ['48%'],
            hoverAnimation: false,
            center: ['50%', '50%'],
            itemStyle: {
              color: 'rgba(66, 66, 66, .1)',
              borderWidth: 1,
              borderColor: '#0078ff'
            },
            data: [100]
          }
        ]
      }
    }
  },
  methods: {
    init () {
      if (this.chart) this.chart.clear()
      this.chart = this.$echarts.init(this.$refs.chart)
      this.chart && this.chart.setOption(this.option)
    }
  }
}
</script>

<style lang="less" scoped>

</style>
