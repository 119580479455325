<template>
  <div class="equipment-table" v-if="list.length">
    <div class="equipment-title">
      <card-title title="设备列表"></card-title>
    </div>
    <ul class="tab-list">
      <li class="tab-item" v-for="(item,index) in tabList" :class="{active:index===currentIndex}" :key="index">
        {{ item }}
      </li>
    </ul>
    <ul class="table">
      <li class="item title">
        <p class="item0">序号</p>
        <p class="item1">设备ID</p>
        <p class="item2">电量</p>
        <p class="item3">循环次数</p>
        <p class="item4">信号值</p>
        <p class="item5">里程</p>
      </li>
      <div class="table-scroll-wrapper">
        <vue-seamless-scroll
          :data="list"
          :class-option="defaultOption"
          :current-list="[-2.5,-645]"
          @on-current="currentIndex=$event">
          <li class="item" v-for="(item,index) in list" :key="item.id+1">
            <p class="item0">{{ index + 1 }}</p>
            <p class="item1">{{ item.deviceId }}</p>
            <p class="item2">{{ item.power }}%</p>
            <p class="item3">{{ item.batLoop }}</p>
            <p class="item4">{{ item.signalValue }}</p>
            <p class="item5">{{ item.mileageOfTheDay }}km</p>
          </li>
          <li class="item" v-for="(item,index) in totalList" :key="item.id+2">
            <p class="item0">{{ index + 1 }}</p>
            <p class="item1">{{ item.deviceId }}</p>
            <p class="item2">{{ item.power }}%</p>
            <p class="item3">{{ item.batLoop }}</p>
            <p class="item4">{{ item.signalValue }}</p>
            <p class="item5">{{ item.totalMileage }}km</p>
          </li>
        </vue-seamless-scroll>
      </div>
    </ul>
  </div>
</template>

<script>
import cardTitle from '../../components/card-title'
import vueSeamlessScroll from '../scroll-list'

export default {
  name: 'index',
  components: {
    cardTitle,
    vueSeamlessScroll
  },
  props: {
    list: {
      type: Array,
      default () {
        return []
      }
    },
    totalList: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      tabList: ['日行驶里程TOP10', '总行驶里程TOP10'],
      currentIndex: 0,
      defaultOption: {
        step: 0.5, // 数值越大速度滚动越快
        limitMoveNum: 2, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: false, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000 // 单步运动停止的时间(默认值1000ms)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.equipment-table {
  position: relative;
  flex: 1;
  //padding: 40px 40px 0 20px;
  height: 100px;
  overflow: hidden;
  background: url("../../assets/images/eq-table.png") no-repeat;
  margin-left: 50px;
  margin-right: 65px;

  .table-scroll-wrapper {
    height: 311px;
    overflow: hidden;
  }

  .tab-list {
    text-align: right;
    height: 80px;
    line-height: 80px;

    .tab-item {
      display: inline-block;
      width: 380px;
      text-align: center;
      font-size: 36px;
      color: #fff;

      &.active {
        color: #00A8FF;
        background: rgba(241, 241, 241, 0.15);
      }

      &:first-child.active {
        background: url("../../assets/images/eq-tab-select.png") no-repeat;
      }
    }
  }

  .equipment-title {
    position: absolute;
    left: 0;
    top: 0;
  }

  .item {
    display: flex;
    width: 100%;
    font-size: 28px;
    color: #D4EAF6;
    padding: 18px 0;

    &:nth-child(odd) {
      background: rgba(241, 241, 241, 0.15);
    }

    &.title {
      font-size: 36px;
      color: #00A8FF;
      padding: 18px 0;
      box-shadow: 0 5px 10px -5px rgba(0, 168, 255, 0.2);
    }

    .item0 {
      flex: 0 0 10%;
      text-align: center
    }

    .item1 {
      flex: 0 0 25%;
      text-align: center;
    }

    .item2 {
      flex: 0 0 10%;
      text-align: center
    }

    .item3 {
      flex: 0 0 20%;
      text-align: center
    }

    .item4 {
      flex: 0 0 15%;
      text-align: center
    }

    .item5 {
      flex: 0 0 20%;
      text-align: center
    }
  }
}
</style>
