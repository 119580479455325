<template>
  <div class="chart" id="chart" ref="chart"></div>
</template>

<script>
import { barStep } from '../../config'

export default {
  name: 'pillar-single',
  props: {
    xData: {
      type: Array,
      default () {
        return []
      }
    },
    yData: {
      type: Array,
      default () {
        return []
      }
    }
  },
  computed: {
    option () {
      return {
        legend: {
          show: true,
          icon: 'rect',
          orient: 'vertical',
          right: '5%',
          top: 0,
          itemHeight: 4,
          itemWidth: 40,
          textStyle: {
            color: '#fff',
            fontSize: 22
          }
        },
        grid: {
          left: '5%',
          right: '15%',
          top: '9%',
          bottom: '32%'
        },
        tooltip: {
          show: true,
          trigger: 'axis',
          formatter (params) {
            let text = ''
            params.forEach((item, index) => {
              if (index > 0) {
                text += '<br>'
              }
              const { seriesName, marker, name, data } = item
              text += `${seriesName}</br>${marker} ${name}万KM: ${data}辆`
            })
            return text
          }
        },
        xAxis: [
          {
            type: 'category',
            data: this.xData,
            axisLabel: {
              textStyle: {
                color: '#fff',
                fontSize: 20
              },
              rotate: 270,
              margin: 20
            },
            axisLine: {
              lineStyle: {
                color: '#2d454b'
              }
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: '#2d454b',
                width: 1
              }
            },
            axisTick: {
              show: false
            },
            boundaryGap: false,
            name: '里程/万KM',
            nameTextStyle: {
              color: '#fff',
              fontSize: 20
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            axisTick: {
              show: false
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: '#2d454b',
                width: 1
              }
            },
            axisLine: {
              lineStyle: {
                color: '#2d454b'
              }
            },
            axisLabel: {
              textStyle: {
                color: '#fff',
                fontSize: 20
              },
              margin: 20
            },
            name: '辆',
            nameTextStyle: {
              color: '#fff',
              fontSize: 20
            }
          }
        ],
        series: [
          {
            name: '2019年',
            type: 'line',
            data: this.yData,
            symbolSize: 10,
            symbol: 'emptyCircle',
            smooth: true,
            borderWidth: 2,
            lineStyle: {
              color: '#3dbcff'
            },
            itemStyle: {
              normal: {
                color: '#3dbcff'
              }
            },
            areaStyle: {
              color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: 'rgba(2,158,255,0.5)'
                },
                {
                  offset: 1,
                  color: 'rgba(2,158,255,0)'
                }
              ])
            }
          }
          // {
          //   name: '2020',
          //   type: 'line',
          //   data: [167, 175, 202, 248, 302, 438, 495, 482, 605],
          //   symbolSize: 10,
          //   symbol: 'emptyCircle',
          //   smooth: true,
          //   borderWidth: 2,
          //   lineStyle: {
          //     color: '#2dff19'
          //   },
          //   itemStyle: {
          //     normal: {
          //       color: '#2dff19'
          //     }
          //   },
          //   areaStyle: {
          //     color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
          //       {
          //         offset: 0,
          //         color: 'rgba(23,222,13,0.5)'
          //       },
          //       {
          //         offset: 1,
          //         color: 'rgba(23,222,13,0)'
          //       }
          //     ])
          //   }
          // },
          // {
          //   name: '2021',
          //   type: 'line',
          //   data: [97, 55, 99, 222, 666, 333, 26, 66, 88],
          //   symbolSize: 10,
          //   symbol: 'emptyCircle',
          //   smooth: true,
          //   borderWidth: 2,
          //   lineStyle: {
          //     color: '#ffa523'
          //   },
          //   itemStyle: {
          //     normal: {
          //       color: '#ffa523'
          //     }
          //   },
          //   areaStyle: {
          //     color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
          //       {
          //         offset: 0,
          //         color: 'rgba(241,160,43,0.5)'
          //       },
          //       {
          //         offset: 1,
          //         color: 'rgba(241,160,43,0)'
          //       }
          //     ])
          //   }
          // }
        ]
      }
    }
  },
  methods: {
    init () {
      if (this.chart) this.chart.clear()
      this.chart = this.$echarts.init(this.$refs.chart)
      this.chart && this.chart.setOption(this.option)
      this.autoTooltip()
    },
    autoTooltip () {
      let index = 0
      this.$nextTick(() => {
        this.chart && this.chart.dispatchAction({
          type: 'showTip',
          seriesIndex: 0,
          dataIndex: index
        })
        if (this.xData.length > 1) {
          index++
          if (this.timer) clearInterval(this.timer)
          this.timer = setInterval(() => {
            this.chart && this.chart.dispatchAction({
              type: 'showTip',
              seriesIndex: 0,
              dataIndex: index
            })
            index++
            if (index >= this.xData.length) {
              index = 0
            }
          }, barStep * 1000)
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>

</style>
