<template>
  <div class="chart" id="chart" ref="chart"></div>
</template>

<script>
export default {
  name: 'pillar-single',
  props: {
    xData: {
      type: Array,
      default () {
        return []
      }
    },
    yData: {
      type: Array,
      default () {
        return []
      }
    },
    color: {
      type: String,
      default: 'blue'
    },
    unit: {
      type: String,
      default: '人'
    }
  },
  data () {
    const _this = this
    return {
      colorList: {
        yellow: {
          line: '#ffa523',
          area: new _this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: 'rgba(241,160,43,0.5)'
            },
            {
              offset: 1,
              color: 'rgba(241,160,43,0)'
            }
          ])
        },
        blue: {
          line: '#3dbcff',
          area: new _this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: 'rgba(2,158,255,0.5)'
            },
            {
              offset: 1,
              color: 'rgba(2,158,255,0)'
            }
          ])
        }
      }
    }
  },
  computed: {
    option () {
      return {
        grid: {
          left: '10%',
          right: '10%',
          top: '10%',
          bottom: '15%'
        },
        xAxis: [
          {
            type: 'category',
            data: this.xData,
            axisLabel: {
              textStyle: {
                color: '#fff',
                fontSize: 20
              },
              margin: 20
            },
            axisLine: {
              lineStyle: {
                color: '#2d454b'
              }
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: '#2d454b',
                width: 1
              }
            },
            axisTick: {
              show: false
            },
            boundaryGap: false
          }
        ],
        yAxis: [
          {
            type: 'value',
            axisTick: {
              show: false
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: '#2d454b',
                width: 1
              }
            },
            axisLine: {
              lineStyle: {
                color: '#2d454b'
              }
            },
            axisLabel: {
              textStyle: {
                color: '#fff',
                fontSize: 20
              },
              margin: 20
            },
            name: this.unit,
            nameTextStyle: {
              color: '#fff',
              fontSize: 20
            }
          }
        ],
        series: [
          {
            type: 'line',
            data: this.yData,
            symbolSize: 10,
            symbol: 'emptyCircle',
            smooth: true,
            borderWidth: 2,
            lineStyle: {
              color: this.colorList[this.color].line
            },
            itemStyle: {
              normal: {
                color: this.colorList[this.color].line
              }
            },
            areaStyle: {
              color: this.colorList[this.color].area
            }
          }
        ]
      }
    }
  },
  methods: {
    init () {
      if (this.chart) this.chart.clear()
      this.chart = this.$echarts.init(this.$refs.chart)
      this.chart && this.chart.setOption(this.option)
    }
  }
}
</script>

<style lang="less" scoped>

</style>
