<template>
  <div class="chart" id="chart" ref="chart"></div>
</template>

<script>
export default {
  name: 'pillar-single',
  props: {
    seriesData: {
      type: Array,
      default () {
        return []
      }
    }
  },
  computed: {
    title1 () {
      return this.seriesData.length && this.seriesData[0].province
    },
    title2 () {
      return this.seriesData.length && this.seriesData[1].province
    },
    title3 () {
      return this.seriesData.length && this.seriesData[2].province
    },
    data1 () {
      return this.seriesData.length && this.seriesData[0].qty
    },
    data2 () {
      return this.seriesData.length && this.seriesData[1].qty
    },
    data3 () {
      return this.seriesData.length && this.seriesData[2].qty
    },
    maxData () {
      const max = Math.max.apply(null, [this.data1, this.data2, this.data3])
      return Math.ceil(max / 1000) * 1000
    },
    option () {
      return {
        title: [
          {
            x: '17%',
            bottom: 30,
            text: this.title2,
            textStyle: {
              fontWeight: 'normal',
              fontSize: 30,
              color: '#fff'
            }
          },
          {
            x: '42%',
            bottom: 30,
            text: this.title1,
            textStyle: {
              fontWeight: 'normal',
              fontSize: 30,
              color: '#fff'
            }
          },
          {
            x: '67%',
            bottom: 30,
            text: this.title3,
            textStyle: {
              fontWeight: 'normal',
              fontSize: 30,
              color: '#fff'
            }
          }
        ],
        series: [
          {
            type: 'gauge',
            center: ['45%', '55%'], // 默认全局居中
            radius: '80%',
            splitNumber: 10,
            min: 0,
            max: this.maxData,
            startAngle: 200,
            endAngle: -20,
            clockwise: true,
            axisLine: {
              show: true,
              lineStyle: {
                width: 2,
                shadowBlur: 0,
                color: [
                  [1, '#0599db']
                ]
              }
            },
            axisTick: {
              show: true,
              lineStyle: {
                color: '#0599db',
                width: 3
              },
              length: -15,
              splitNumber: 10
            },
            splitLine: {
              show: true,
              length: -20,
              lineStyle: {
                color: '#0599db'
              }
            },
            axisLabel: {
              distance: -25,
              textStyle: {
                color: '#0599db',
                fontSize: '15',
                fontWeight: 'bold'
              }
            },
            pointer: {
              show: 0
            },
            detail: {
              show: false
            },
            data: [
              {
                name: '',
                value: this.maxData
              }
            ]
          },
          {
            startAngle: 200,
            endAngle: -20,
            type: 'gauge',
            center: ['45%', '55%'], // 默认全局居中
            radius: '70%',
            min: 0,
            max: this.maxData,
            splitNumber: 0,
            axisLine: { // 坐标轴线
              lineStyle: {
                color: [
                  [0.66, '#dddddd'],
                  [1, '#dddddd']
                ], // 属性lineStyle控制线条样式
                width: 4
              }
            },
            axisLabel: { // 坐标轴小标记
              textStyle: { // 属性lineStyle控制线条样式
                fontWeight: 'bolder',
                fontSize: 16,
                color: 'rgba(30,144,255,0)'
              }
            },
            splitLine: { // 分隔线
              length: 10, // 属性length控制线长
              lineStyle: { // 属性lineStyle（详见lineStyle）控制线条样式
                width: 0,
                color: '#444'
              }
            },
            pointer: { // 分隔线 指针
              color: '#666666',
              width: 0,
              length: 230
            },
            detail: {
              show: false
            }
          },
          {
            name: this.title1,
            type: 'gauge',
            startAngle: 200,
            endAngle: -20,
            radius: '80%',
            center: ['45%', '65%'], // 默认全局居中
            min: 0,
            max: this.maxData,
            axisLine: {
              show: false,
              lineStyle: {
                width: 25,
                shadowBlur: 0,
                color: [
                  [0.2, '#00FAFC'],
                  [0.4, '#3BD542'],
                  [0.6, '#E3F424'],
                  [0.8, '#7E48DA'],
                  [1, '#E531A8']
                ]
              }
            },
            axisTick: {
              show: false
            },
            splitLine: {
              show: false
            },
            axisLabel: {
              show: false
            },
            pointer: {
              show: true
            },
            detail: {
              show: true,
              offsetCenter: [0, '20%'],
              textStyle: {
                fontSize: 30,
                color: '#0599db'
              }
            },
            itemStyle: {
              normal: {
                color: '#0599db'
              }
            },
            // 第一
            data: [
              {
                value: this.data1
              }
            ]
          },
          {
            type: 'gauge',
            center: ['20%', '55%'], // 默认全局居中
            radius: '55%',
            splitNumber: 10,
            min: 0,
            max: this.maxData,
            endAngle: 45,
            clockwise: true,
            axisLine: {
              show: true,
              lineStyle: {
                width: 2,
                shadowBlur: 0,
                color: [
                  [1, '#7ae070']
                ]
              }
            },
            axisTick: {
              show: true,
              lineStyle: {
                color: '#7ae070',
                width: 3
              },
              length: -15,
              splitNumber: 10
            },
            splitLine: {
              show: true,
              length: -20,
              lineStyle: {
                color: '#7ae070'
              }
            },
            axisLabel: {
              distance: -25,
              textStyle: {
                color: '#7ae070',
                fontSize: '15',
                fontWeight: 'bold'
              }
            },
            pointer: {
              show: 0
            },
            detail: {
              show: false
            },
            data: [
              {
                name: '',
                value: this.maxData
              }
            ]
          },
          {
            type: 'gauge',
            center: ['20%', '55%'], // 默认全局居中
            radius: '45%',
            min: 0,
            max: this.maxData,
            endAngle: 45,
            splitNumber: 0,
            axisLine: { // 坐标轴线
              lineStyle: {
                color: [
                  [0.66, '#dddddd'],
                  [1, '#dddddd']
                ], // 属性lineStyle控制线条样式
                width: 4
              }
            },
            axisLabel: { // 坐标轴小标记
              textStyle: { // 属性lineStyle控制线条样式
                fontWeight: 'bolder',
                fontSize: 16,
                color: 'rgba(30,144,255,0)'
              }
            },
            splitLine: { // 分隔线
              length: 10, // 属性length控制线长
              lineStyle: { // 属性lineStyle（详见lineStyle）控制线条样式
                width: 0,
                color: '#444'
              }
            },
            pointer: { // 分隔线 指针
              color: '#666666',
              width: 0,
              length: 230
            },
            detail: {
              show: false
            }
          },
          {
            name: this.title2,
            type: 'gauge',
            endAngle: 45,
            radius: '50%',
            center: ['20%', '65%'], // 默认全局居中
            min: 0,
            max: this.maxData,
            axisLine: {
              show: false,
              lineStyle: {
                width: 25,
                shadowBlur: 0,
                color: [
                  [0.2, '#00FAFC'],
                  [0.4, '#3BD542'],
                  [0.6, '#E3F424'],
                  [0.8, '#7E48DA'],
                  [1, '#E531A8']
                ]
              }
            },
            axisTick: {
              show: false
            },
            splitLine: {
              show: false,
              length: 20
            },
            axisLabel: {
              show: false
            },
            pointer: {
              show: true
            },
            detail: {
              show: true,
              offsetCenter: [0, '30%'],
              textStyle: {
                fontSize: 26,
                color: '#7ae070'
              }
            },
            itemStyle: {
              normal: {
                color: '#7ae070'
              }
            },
            // 第二
            data: [
              {
                value: this.data2
              }
            ]
          },
          {
            type: 'gauge',
            center: ['70%', '55%'], // 默认全局居中
            radius: '55%',
            splitNumber: 10,
            min: 0,
            max: this.maxData,
            startAngle: 140,
            endAngle: -45,
            clockwise: true,
            axisLine: {
              show: true,
              lineStyle: {
                width: 2,
                shadowBlur: 0,
                color: [
                  [1, '#fb4542']
                ]
              }
            },
            axisTick: {
              show: true,
              lineStyle: {
                color: '#fb4542',
                width: 3
              },
              length: -15,
              splitNumber: 10
            },
            splitLine: {
              show: true,
              length: -20,
              lineStyle: {
                color: '#fb4542'
              }
            },
            axisLabel: {
              distance: -25,
              textStyle: {
                color: '#fb4542',
                fontSize: '15',
                fontWeight: 'bold'
              }
            },
            pointer: {
              show: 0
            },
            detail: {
              show: false
            },
            data: [
              {
                name: '',
                value: this.maxData
              }
            ]
          },
          {
            type: 'gauge',
            center: ['70%', '55%'], // 默认全局居中
            radius: '45%',
            min: 0,
            max: this.maxData,
            startAngle: 140,
            endAngle: -45,
            splitNumber: 0,
            axisLine: { // 坐标轴线
              lineStyle: {
                color: [
                  [0.66, '#dddddd'],
                  [1, '#dddddd']
                ], // 属性lineStyle控制线条样式
                width: 4
              }
            },
            axisLabel: { // 坐标轴小标记
              textStyle: { // 属性lineStyle控制线条样式
                fontWeight: 'bolder',
                fontSize: 16,
                color: 'rgba(30,144,255,0)'
              }
            },
            splitLine: { // 分隔线
              length: 10, // 属性length控制线长
              lineStyle: { // 属性lineStyle（详见lineStyle）控制线条样式
                width: 0,
                color: '#444'
              }
            },
            pointer: { // 分隔线 指针
              color: '#666666',
              width: 0,
              length: 230
            },
            detail: {
              show: false
            }
          },
          {
            name: this.title3,
            type: 'gauge',
            startAngle: 140,
            endAngle: -45,
            radius: '50%',
            center: ['70%', '65%'], // 默认全局居中
            min: 0,
            max: this.maxData,
            axisLine: {
              show: false,
              lineStyle: {
                width: 25,
                shadowBlur: 0,
                color: [
                  [0.2, '#00FAFC'],
                  [0.4, '#3BD542'],
                  [0.6, '#E3F424'],
                  [0.8, '#7E48DA'],
                  [1, '#E531A8']
                ]
              }
            },
            axisTick: {
              show: false
            },
            splitLine: {
              show: false,
              length: 20
            },
            axisLabel: {
              show: false
            },
            pointer: {
              show: true
            },
            detail: {
              show: true,
              offsetCenter: [0, '30%'],
              textStyle: {
                fontSize: 26,
                color: '#fb4542'
              }
            },
            itemStyle: {
              normal: {
                color: '#fb4542'
              }
            },
            // 第三
            data: [
              {
                value: this.data3
              }
            ]
          }
        ]
      }
    }
  },
  methods: {
    init () {
      if (this.chart) this.chart.clear()
      this.chart = this.$echarts.init(this.$refs.chart)
      this.chart && this.chart.setOption(this.option)
    }
  }
}
</script>

<style lang="less" scoped>

</style>
