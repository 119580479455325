<template>
  <div class="index2">
    <div class="index2-header" @click="$router.push('/index2')">
      <div class="index2-header-h">
        <c-title title="电池回收" title-en="BATTERY RECYCLING"></c-title>
      </div>
      <div class="index2-header-h">
        <c-title title="租赁运营" title-en="LEASE OPERATION"></c-title>
      </div>
    </div>
    <div class="index2-content">
      <div class="index2-i">
        <div class="index2-i-h">
          <div class="index2-i-h-i">
            <card-title :title="statisticsData.title"></card-title>
            <statistics ref="statistics" :data="statisticsData.data"></statistics>
          </div>
          <div class="index2-i-h-i">
            <card-title :title="rankData.title"></card-title>
            <rank :list="rankData.data"></rank>
          </div>
        </div>
        <div class="index2-i-h index2-i-h-h">
          <card-title :title="deviceData.title"></card-title>
          <div class="index2-i-h-i-l">
            <div class="index2-i-h-i-h">
              <dash
                ref="dash"
                :total="deviceData.sumDevice"
                :on-count="deviceData.onlineDevice"
                :off-count="deviceData.offlineDevice"
              ></dash>
            </div>
            <div class="index2-i-h-i-h" style="display: flex;">
              <div style="flex: 1;">
                <cycle
                  ref="cycle1"
                  :percent="deviceData.usageRate.toFixed(2)"
                ></cycle>
              </div>
              <div style="flex: 1;">
                <cycle
                  ref="cycle2"
                  title="设备总出租率"
                  :percent="deviceData.occupancyRate.toFixed(2)"
                ></cycle>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="index2-i">
        <div class="index2-i-h">
          <div class="index2-i-h-i">
            <card-title :title="monthData.title"></card-title>
            <line-single
              ref="lineSingle1"
              color="yellow"
              unit="kWh"
              :x-data="monthData.xData"
              :y-data="monthData.yData"></line-single>
          </div>
          <div class="index2-i-h-i">
            <card-title :title="staffData.title"></card-title>
            <line-single
              ref="lineSingle2"
              :x-data="staffData.xData"
              :y-data="staffData.yData"></line-single>
          </div>
        </div>
        <div class="index2-i-h index2-i-h-h">
          <card-title :title="rent1Data.title"></card-title>
          <div class="index2-i-h-i-l" style="color: red">
            <div class="index2-i-h-i-h">
              <pie-3d
                ref="pie3d"
                :angle="330"
                :list="rent1Data.data"
              ></pie-3d>
            </div>
            <div class="index2-i-h-i-h">
              <pie-3d
                ref="pie3d2"
                :current-index="1"
                :angle="270"
                :list="rent2Data.data"
              ></pie-3d>
            </div>
          </div>
        </div>
      </div>
      <div class="index2-i">
        <div class="index2-i-h">
          <div class="index2-i-h-i">
            <card-title :title="useTypeData.title"></card-title>
            <dash-single
              ref="dashSingle1"
              :title="useTypeData.title"
              :type-data="useTypeData.x"
              :data="useTypeData.data"
              :color-list="['#009eff','#ffa11a']"
            ></dash-single>
          </div>
          <div class="index2-i-h-i">
            <card-title :title="batteryTypeData.title"></card-title>
            <dash-single
              ref="dashSingle2"
              :name-width="80"
              :title="batteryTypeData.title"
              :type-data="batteryTypeData.x"
              :data="batteryTypeData.data"
              :color-list="['#009eff','#ffa11a','#18e202','#6E55C9']"
            ></dash-single>
          </div>
          <div class="index2-i-h-i">
            <card-title :title="supplierData.title"></card-title>
            <dash-single
              ref="dashSingle3"
              :name-width="60"
              :title="supplierData.title"
              :type-data="supplierData.x"
              :data="supplierData.data"
              :color-list="['#449DF8','#5AC3CB','#69DE43','#F2A441','#E8544B','#6E55C9','#A19C9C']"
            ></dash-single>
          </div>
        </div>
        <!--           style="padding:100px 203px 98px 74px;"  -->
        <div class="index2-i-h index2-i-h-h" style="padding-bottom: 98px;padding-top: 100px;">
          <equipment-table
            :list="equipmentList.list"
            :total-list="equipmentList.totalList">
          </equipment-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import cTitle from '../components/c-title'
import statistics from '../components/statistics'
import rank from '../components/rank'
import cardTitle from '../components/card-title'
import lineSingle from '../components/line-single'
import dash from '../components/dash'
import dashSingle from '../components/dash-single'
import cycle from '../components/cycle'
import equipmentTable from '../components/equipment-table'
import pie3d from '../components/pie-3d'
import { getRecoveryData, getRentData } from '../api'
import { pageStep } from '../config'

export default {
  components: {
    cTitle,
    cardTitle,
    statistics,
    rank,
    lineSingle,
    dash,
    dashSingle,
    cycle,
    equipmentTable,
    pie3d
  },
  data () {
    return {
      timer: null,
      statisticsData: {
        title: '',
        data: []
      },
      rankData: {
        title: '',
        data: []
      },
      monthData: {
        title: '',
        xData: [],
        yData: []
      },
      staffData: {
        title: '',
        xData: [],
        yData: []
      },
      deviceData: {
        usageRate: 0,
        occupancyRate: 0
      },
      useTypeData: {},
      batteryTypeData: {},
      supplierData: {},
      rent1Data: [],
      rent2Data: [],
      equipmentList: {
        list: [],
        totalList: []
      },
      bgImg: require('../assets/images/header-title1.png'),
      screenWidth: document.documentElement.offsetWidth
    }
  },
  created () {
    this.getRecoveryData()
    if (this.timer) {
      clearInterval(this.timer)
    } else {
      this.timer = setInterval(() => {
        this.getRecoveryData()
      }, pageStep * 1000)
    }
  },
  destroyed () {
    clearInterval(this.timer)
  },
  methods: {
    resetData () {
      this.statisticsData.data.forEach((item) => {
        item.value = 0
      })
    },
    getRecoveryData () {
      this.resetData()
      getRecoveryData().then(({ result }) => {
        const { statistics, rank, month, staff, useType, batteryType, supplier } = result
        // 1.1回收统计
        this.statisticsData.title = statistics.title
        this.statisticsData.data = statistics.data

        // 1.2回收排行
        this.rankData.title = rank.title
        this.rankData.data = rank.data

        // 3.1月回收量
        this.monthData.title = month.title
        this.monthData.xData = month.x
        this.monthData.yData = month.data

        // 3.2月回收人数
        this.staffData.title = staff.title
        this.staffData.xData = staff.x
        this.staffData.yData = staff.data

        // 5.1 类别占比
        this.useTypeData = useType

        // 5.2 成分占比
        this.batteryTypeData = batteryType

        // 5.3品牌占比
        this.supplierData = supplier
        this.$nextTick(() => {
          this.$refs.lineSingle1.init()
          this.$refs.lineSingle2.init()
          this.$refs.dashSingle1.init()
          this.$refs.dashSingle2.init()
          this.$refs.dashSingle3.init()
        })
      }).then(() => {
        this.getRentData()
      })
    },
    getRentData () {
      getRentData().then(({ result }) => {
        const { rent1, rent2, device, deviceTopDay, deviceTopTotal } = result
        this.deviceData = device.data
        this.deviceData.title = device.title
        this.rent1Data = rent1
        this.rent2Data = rent2
        this.equipmentList.list = deviceTopDay.data
        this.equipmentList.totalList = deviceTopTotal.data
        this.$nextTick(() => {
          this.$refs.dash.init()
          this.$refs.cycle1.init()
          this.$refs.cycle2.init()
          this.$refs.pie3d.init()
          this.$refs.pie3d2.init()
        })
      })
    }
  }
}
</script>
<style lang="less" scoped>
.index2 {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: url('../assets/images/bg1.png') no-repeat;
  background-size: cover;

  .index2-header {
    display: flex;
    padding-top: 50px;

    .index2-header-h {
      flex: 1;
    }
  }

  .index2-content {
    flex: 1;
    padding: 27px 64px;
  }

  .index2-i {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .index2-i-h {
      box-sizing: border-box;
      display: flex;
      flex: 1;
      height: 665px;
      background-size: cover;
      background: url("../assets/images/card-bg3.png") no-repeat;
      padding: 114px 74px;

      &.index2-i-h-h {
        flex-direction: column;
      }

      .index2-i-h-i {
        flex: 1;
        width: 100%;
      }

      .index2-i-h-i-l {
        flex: 1;
        display: flex;
      }

      .index2-i-h-i-h {
        flex: 1;
      }
    }
  }
}
</style>
