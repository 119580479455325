<template>
  <div class="rank">
    <ul class="list">
      <li class="item" v-for="(item,index) in list" :key="index">
        <p class="no">NO.{{ index + 1 }}</p>
        <p class="name">{{ item.name }}</p>
        <img v-if="item.authFlag===1" src="../../assets/images/authentication.png" alt="">
        <p class="count">{{ item.value }}</p>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'index',
  props: {
    list: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {}
  }
}
</script>

<style lang="less" scoped>
.rank {
  padding: 40px 0 0 100px;

  .list {
    .item {
      display: flex;
      align-items: center;
      width: 639px;
      height: 36px;
      margin-bottom: 25px;
      padding-left: 10px;
      font-size: 24px;
      color: #BCF0FE;

      &:nth-child(1) {
        background: url("../../assets/images/no1.png") no-repeat;
      }

      &:nth-child(2) {
        background: url("../../assets/images/no2.png") no-repeat;
      }

      &:nth-child(3) {
        background: url("../../assets/images/no3.png") no-repeat;
      }

      &:nth-child(4) {
        background: url("../../assets/images/no4.png") no-repeat;
      }

      &:nth-child(5) {
        background: url("../../assets/images/no4.png") no-repeat;
      }

      .no {
        font-size: 28px;
        font-style: italic;
        color: rgba(255, 255, 255, .7);
        flex: 0 0 140px;
        width: 140px;
      }

      .name {
        flex: 0 0 80px;
        width: 80px;
      }

      .image {
        display: inline-block;
        width: 20px;
        height: 24px;
      }

      .count {
        flex: 1;
        text-align: right;
      }
    }
  }
}
</style>
