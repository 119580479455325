<template>
  <div class="card-title">
    <img class="image" src="../../assets/images/triangle.png" alt="">
    <p class="name">{{ title }}</p>
  </div>
</template>

<script>
export default {
  name: 'card-title',
  props: {
    title: {
      title: {
        type: String,
        default: ''
      }
    }
  }
}
</script>

<style lang="less" scoped>
.card-title {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;

  .image {
    flex: 0 0 36px;
    width: 36px;
    height: 47px;
  }

  .name {
    flex: 1;
    font-size: 50px;
    color: #65CCFF;
    font-weight: bold;
    font-family: "微软雅黑";
    padding-left: 17px;
  }
}
</style>
